<template>
  <div>
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm" />
    <div class="header">
      <el-form :inline="true">
        <el-form-item label="台账编号:">
          {{ recallLedger.no }}
        </el-form-item>
        <el-form-item v-if="recallLedger.updateDate" label="修改时间:">
          {{ recallLedger.updateDate }}
        </el-form-item>
        <el-form-item v-if="recallLedger.updateUserName" label="最后修改人:">
          {{ recallLedger.updateUserName }}
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <table>
        <caption style="font-size: 24px; font-weight: 600; margin-bottom: 20px">
          农药产品召回记录
        </caption>
        <tbody>
          <tr>
            <td colspan="5" style="font-size: 20px; font-weight: 600">
              应召回农药产品的基本信息
            </td>
          </tr>
          <tr>
            <td>应召回农药产品名称</td>
            <td colspan="2" style="text-align: left">
              <el-input
                style="width: 100%"
                v-model="recallLedger.productName"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
            <td>生产批号</td>
            <td>
              <el-input
                style="width: 100%"
                v-model="recallLedger.batchNo"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>生产企业名称</td>
            <td colspan="4">
              <el-input
                style="width: 100%"
                v-model="recallLedger.manufacturer"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
          <tr>
            <td>召回信息来源</td>
            <td colspan="4" style="text-align: left">
              <div>
                信息来源时间：<el-date-picker
                  v-model="recallLedger.sourceDate"
                  type="date"
                  :disabled="isView"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
              <div>
                <el-checkbox-group
                  v-model="checkSource"
                  @change="checkSourceChange"
                >
                  <div
                    style="margin-top: 10px"
                    v-for="source in sources"
                    :key="source.name"
                  >
                    <el-checkbox
                      :disabled="isView"
                      :label="source.name"
                    ></el-checkbox>
                  </div>
                  <div style="margin-top: 10px">
                    <el-checkbox
                      :disabled="isView"
                      label="4、其他信息来源"
                    ></el-checkbox>
                    <el-input
                      style="width: 100%"
                      v-model="sourceExtra"
                      :disabled="
                        isView || checkSource.indexOf('4、其他信息来源') == -1
                      "
                      type="text"
                    ></el-input>
                  </div>
                </el-checkbox-group>
              </div>
            </td>
          </tr>
          <tr>
            <td>召回原因</td>
            <td colspan="4" style="text-align: left; font-size: 14px">
              <el-radio-group v-model="currentReason">
                <div
                  style="margin-top: 10px"
                  v-for="reason in sourceReasons"
                  :key="reason.name"
                >
                  <el-radio :disabled="isView" :label="reason.id">{{
                    reason.name
                  }}</el-radio>
                </div>
                <div style="margin-top: 10px">
                  <el-radio :disabled="isView" label="其他原因"
                    >其他原因</el-radio
                  >
                  <el-input
                    style="width: 100%"
                    v-model="reasonExtra"
                    :disabled="isView || currentReason != '其他原因'"
                    type="text"
                  ></el-input>
                </div>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <td colspan="5" style="font-size: 20px; font-weight: 600">
              应召回农药产品店内自查及停止销售记录
            </td>
          </tr>
          <tr>
            <td colspan="5" style="text-align: left">
              <div style="margin-left: 150px">
                <div>
                  本店于<el-date-picker
                    v-model="recallLedger.sourceDate"
                    type="date"
                    :disabled="isView"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                  对此批农药进行了自查，经自查：
                  <el-radio-group v-model="recallLedger.checkInfo">
                    <el-radio :disabled="isView" label="0"
                      >本店未购进过该批农药产品</el-radio
                    >
                    <el-radio :disabled="isView" label="1"
                      >发现本店有购进过该批农药产品</el-radio
                    >
                  </el-radio-group>
                </div>
                <div
                  v-if="recallLedger.checkInfo == '1'"
                  style="margin-top: 10px"
                >
                  供应商名称：
                  <el-input
                    style="width: 200px"
                    v-model="recallLedger.supplierName"
                    :disabled="isView"
                    type="text"
                  ></el-input
                  >, 供货人名称：<el-input
                    style="width: 200px"
                    v-model="recallLedger.supplierPerson"
                    :disabled="isView"
                    type="text"
                  ></el-input
                  >, 供货人电话：<el-input
                    style="width: 200px"
                    v-model="recallLedger.supplierContact"
                    :disabled="isView"
                    type="text"
                  ></el-input
                  >,
                </div>
                <div
                  v-if="recallLedger.checkInfo == '1'"
                  style="margin-top: 10px"
                >
                  购进次数：
                  <el-input
                    style="width: 200px"
                    type="number"
                    v-model="recallLedger.buyNum"
                    :disabled="isView"
                  ></el-input
                  >次, 该批农药产品的整件包装单位：<el-input
                    style="width: 200px"
                    v-model="recallLedger.productUnit"
                    :disabled="isView"
                    type="text"
                  ></el-input
                  >规格：<el-input
                    style="width: 200px"
                    v-model="recallLedger.productSpec"
                    :disabled="isView"
                    type="text"
                  ></el-input>
                </div>
                <div
                  v-if="recallLedger.checkInfo == '1'"
                  style="margin-top: 10px"
                >
                  购进总数量：
                  <el-input
                    style="width: 200px"
                    type="number"
                    v-model="recallLedger.buyTotal"
                    :disabled="isView"
                  ></el-input
                  >现库存数量：<el-input
                    style="width: 200px"
                    v-model="recallLedger.inventoryNum"
                    :disabled="isView"
                    type="number"
                  ></el-input
                  >已销出数量：<el-input
                    style="width: 200px"
                    v-model="recallLedger.saleNum"
                    :disabled="isView"
                    type="number"
                  ></el-input>
                </div>
                <div
                  v-if="recallLedger.checkInfo == '1'"
                  style="margin-top: 10px"
                >
                  自查发现本店曾购进过该批农药后，于
                  <el-date-picker
                    v-model="recallLedger.stopDate"
                    type="date"
                    :disabled="isView"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                  立即停止了该农药的销售。
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="recallLedger.checkInfo == '1'">
            <td colspan="5" style="font-size: 20px; font-weight: 600">
              通知供货人、生产企业购买人和向农业主管部门报告记录
            </td>
          </tr>
          <tr v-if="recallLedger.checkInfo == '1'">
            <td colspan="5" style="text-align: left">
              <div style="margin-left: 150px">
                <div>
                  本店于
                  <el-date-picker
                    v-model="recallLedger.notificationDate"
                    type="date"
                    :disabled="isView"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker
                  >，向供货方、生产企业电话进行了通知；并向该批农药产品的购买人
                  <el-input
                    style="width: 200px"
                    v-model="recallLedger.notificationPerson"
                    :disabled="isView"
                    type="number"
                    placeholder="请输入数字"
                  ></el-input>
                  人电话进行了通知；
                </div>
                <div>
                  同时，向农业主管部门名称：<el-input
                    style="width: 200px"
                    v-model="recallLedger.notificationDept"
                    :disabled="isView"
                    type="text"
                  ></el-input>
                  电话进行了报告；
                </div>
                <div>
                  得到回复：
                  <el-input
                    v-model="recallLedger.notificationReply"
                    :disabled="isView"
                    type="textarea"
                    rows="3"
                    placeholder="简述"
                  ></el-input>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="recallLedger.checkInfo == '1'">
            <td colspan="5" style="font-size: 20px; font-weight: 600">
              农药产品召回记录
            </td>
          </tr>
          <tr v-if="recallLedger.checkInfo == '1'">
            <td colspan="5" style="text-align: left">
              <div style="margin-left: 150px">
                <div>
                  从停止销售之日起至<el-date-picker
                    v-model="recallLedger.stopEndDate"
                    type="date"
                    :disabled="isView"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </div>
                <div>
                  从本店经营场所、库房共清理召回该批农药数量：
                  <el-input
                    style="width: 200px"
                    v-model="recallLedger.clearNum"
                    :disabled="isView"
                    type="number"
                    placeholder="请输入数字"
                  ></el-input>
                  从
                  <el-input
                    style="width: 200px"
                    v-model="recallLedger.recallPerson"
                    :disabled="isView"
                    type="number"
                    placeholder="请输入数字"
                  ></el-input>
                  人购买人处召回该批农药数量为：
                  <el-input
                    style="width: 200px"
                    v-model="recallLedger.recallNum"
                    :disabled="isView"
                    type="number"
                    placeholder="请输入数字"
                  ></el-input>
                </div>
                <div>
                  总计召回该批农药数量为:
                  <el-input
                    style="width: 200px"
                    v-model="recallLedger.recallTotal"
                    :disabled="isView"
                    type="number"
                    placeholder="请输入数字"
                  ></el-input>
                </div>
                <div>
                  经核对采购（进）货记录，召回的该批农药产品数量与该批农药产品采购（进）货量：
                  <el-radio-group
                    v-model="recallLedger.recallPurchase"
                    :disabled="isView"
                  >
                    <el-radio label="一致">一致</el-radio>
                    <el-radio label="少于进货量">少于进货量</el-radio>
                  </el-radio-group>
                  <el-input
                    v-if="recallLedger.recallPurchase == '少于进货量'"
                    style="width: 200px"
                    v-model="lessNumber"
                    :disabled="isView"
                    type="number"
                    placeholder="请输入数字"
                  ></el-input>
                </div>
                <div v-if="recallLedger.recallPurchase == '少于进货量'">
                  少于进货量原因：
                  <el-input
                    v-model="lessPurchaseReason"
                    :disabled="isView"
                    type="textarea"
                    rows="3"
                    placeholder="简述"
                  ></el-input>
                </div>
              </div>
            </td>
          </tr>
          <tr v-if="recallLedger.checkInfo == '1'">
            <td colspan="5" style="font-size: 20px; font-weight: 600">
              召回农药产品处置记录
            </td>
          </tr>
          <tr v-if="recallLedger.checkInfo == '1'">
            <td>处置日期</td>
            <td>处置数量</td>
            <td>处置去向</td>
            <td>本店处置人签名</td>
            <td>备注</td>
          </tr>
          <tr v-if="recallLedger.checkInfo == '1'">
            <td>
              <el-date-picker
                v-model="recallLedger.disposeDate"
                type="date"
                :disabled="isView"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="recallLedger.disposeNum"
                :disabled="isView"
                type="number"
              ></el-input>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="recallLedger.disposeWhither"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
            <td>
              <div v-if="isView">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="getImg(recallLedger.sign)"
                ></el-image>
              </div>
              <div v-else @click="openSign">
                <div v-if="!recallLedger.sign" class="sign">点击签名</div>
                <el-image
                  v-else
                  style="width: 100px; height: 100px"
                  :src="getImg(recallLedger.sign)"
                ></el-image>
              </div>
            </td>
            <td>
              <el-input
                style="width: 100%"
                v-model="recallLedger.remark"
                :disabled="isView"
                type="text"
              ></el-input>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Sign
      :visible="signatureVisible"
      @finsih="signFisish"
      @close="signatureVisible = false"
    ></Sign>
  </div>
</template>
<script>
import Sign from "@/components/Sign";
import { uploadFile } from "@/api/common";
import { imgUrl } from "../../../../settting";
import {
  getRecallLedgerCode,
  getRecallLedgerDetails,
  addRecallLedger,
  editRecallLedger,
} from "@/api/recallLedger";
import moment from "moment";
export default {
  components: { Sign },
  data() {
    return {
      recallLedger: {
        checkInfo: "0",
        recallPurchase: "一致",
      },
      checkPlace: [],
      isView: false,
      signatureVisible: false,
      sources: [
        {
          name: "接农药执法监管部门通知",
        },
        {
          name: "接上线农药产品供货单位（生产企业或经营单位）通知",
        },
        {
          name: "中国农药信息（http://www.chinapesticide.org.cn/）查询发现",
        },
      ],
      sourceExtra: "",
      checkSource: [],
      sourceReasons: [
        {
          name: "对农林业、人畜安全、农产品质量安全、生态环境等有严重危害或者存在较大风险的",
          id: "对农林业、人畜安全、农产品质量安全、生态环境等有严重危害或者存在较大风险的",
        },
        {
          name: "经农业主管部门抽检，产品质量不合格的",
          id: "经农业主管部门抽检，产品质量不合格的",
        },
        {
          name: "包装不规范，无法上市销售的",
          id: "包装不规范，无法上市销售的",
        },
        {
          name: "农业主管部门公告，自公告规定之日起列入禁止生产、销售、使用名录，不能继续销售，但店内仍有存货的",
          id: "农业主管部门公告，自公告规定之日起列入禁止生产、销售、使用名录，不能继续销售，但店内仍有存货的",
        },
        {
          name: "农业主管部门公告，自公告规定之日起列入限制使用农药定点经营名录，因未取得限制使用农药定点经营合法手续，不能继续销售，但店内仍有存货的",
          id: "农业主管部门公告，自公告规定之日起列入限制使用农药定点经营名录，因未取得限制使用农药定点经营合法手续，不能继续销售，但店内仍有存货的",
        },
        {
          name: "产品滞销，调换销售地域",
          id: "产品滞销，调换销售地域",
        },
      ],
      currentReason:
        "对农林业、人畜安全、农产品质量安全、生态环境等有严重危害或者存在较大风险的",
      reasonExtra: "",
      lessNumber: "",
      lessPurchaseReason: "", // 少于进货量的原因
    };
  },
  methods: {
    init(id, view) {
      if (id) {
        getRecallLedgerDetails(id).then((res) => {
          if (res.code === 1) {
            if (res.data.sourceInfo) {
              const infos = res.data.sourceInfo.split(";");
              const checks = [];
              infos.forEach((v) => {
                const r = this.sources.filter((e) => e == v);
                if (r.length == 0) {
                  this.sourceExtra = v;
                  checks.push("4、其他信息来源");
                } else {
                  checks.push(v);
                }
              });
              this.checkSource = checks;
              this.sources.indexOf(res.data.sourceInfo);
            }
            if (res.data.reason) {
              const result = this.sourceReasons.filter(
                (v) => v.id == res.data.reason
              );
              if (result.length > 0) {
                this.currentReason = result[0].id;
              } else {
                this.currentReason = "其他原因";
                this.reasonExtra = res.data.reason;
              }
            }
            let currentRecallPurchase = "";
            if (res.data.recallPurchase !== "一致") {
              const recallPurchase = res.data.recallPurchase.split("-");
              (currentRecallPurchase = "少于进货量"),
                (this.lessNumber = recallPurchase[0]);
              this.lessPurchaseReason = recallPurchase[1];
            } else {
              currentRecallPurchase = "一致";
            }
            this.recallLedger = {
              ...res.data,
              recallPurchase: currentRecallPurchase,
            };
            this.isView = view;
          }
        });
      } else {
        getRecallLedgerCode().then((res) => {
          if (res.code == 1) {
            this.recallLedger = {
              ...this.recallLedger,
              no: res.data,
              checkDate: moment().format("YYYY-MM-DD"),
            };
          }
        });
      }
    },
    getImg(url) {
      return imgUrl + url;
    },

    back() {
      this.$emit("back");
    },
    checkSourceChange(val) {
      this.checkSource = val;
    },
    submitForm() {
      if (this.checkSource.length > 0) {
        if (this.checkSource.indexOf("4、其他信息来源") > -1) {
          this.checkSource.splice(
            this.checkSource.indexOf("4、其他信息来源"),
            1,
            this.sourceExtra
          );
          this.recallLedger.sourceInfo = this.checkSource.join(";");
        } else {
          this.recallLedger.sourceInfo = this.checkSource.join(";");
        }
      } else {
        return this.$message.error("请选择召回信息来源");
      }
      if (this.currentReason == "其他原因") {
        this.recallLedger.reason = this.reasonExtra;
      } else {
        this.recallLedger.reason = this.currentReason;
      }
      if (!this.recallLedger.productName) {
        return this.$message.error("请输入农药名称");
      }
      if (!this.recallLedger.batchNo) {
        return this.$message.error("请输入生产批号");
      }
      if (!this.recallLedger.manufacturer) {
        return this.$message.error("请输入生产企业");
      }
      if (!this.recallLedger.sourceDate) {
        return this.$message.error("请输入信息来源时间");
      }
      if (this.recallLedger.checkInfo == "1") {
        if (!this.recallLedger.supplierName) {
          return this.$message.error("请输入供应商名称");
        }
        if (!this.recallLedger.supplierPerson) {
          return this.$message.error("请输入供货人姓名");
        }
        if (!this.recallLedger.supplierContact) {
          return this.$message.error("请输入供货人电话");
        }
        if (!this.recallLedger.buyNum) {
          return this.$message.error("请输入购进次数");
        }
        if (!this.recallLedger.productUnit) {
          return this.$message.error("请输入包装单位");
        }
        if (!this.recallLedger.productSpec) {
          return this.$message.error("请输入规格");
        }
        if (!this.recallLedger.buyTotal) {
          return this.$message.error("请输入购进总数量");
        }
        if (!this.recallLedger.inventoryNum) {
          return this.$message.error("请输入库存数量");
        }
        if (!this.recallLedger.saleNum) {
          return this.$message.error("请输入已销出数量");
        }
        if (!this.recallLedger.stopDate) {
          return this.$message.error("请输入停止销售日期");
        }
        if (!this.recallLedger.notificationDate) {
          return this.$message.error("请输入通知日期");
        }
        if (!this.recallLedger.notificationPerson) {
          return this.$message.error("请输入购买人数");
        }
        if (!this.recallLedger.notificationDept) {
          return this.$message.error("请输入农业主管部门");
        }
        if (!this.recallLedger.notificationReply) {
          return this.$message.error("请输入主管部门回复");
        }
        if (!this.recallLedger.stopEndDate) {
          return this.$message.error("请输入停止销售日期");
        }
        if (!this.recallLedger.clearNum) {
          return this.$message.error("请输入清理农药数量");
        }
        if (!this.recallLedger.recallPerson) {
          return this.$message.error("请输入清理农药购买人数");
        }
        if (!this.recallLedger.recallNum) {
          return this.$message.error("请输入召回农药数量");
        }
        if (!this.recallLedger.recallTotal) {
          return this.$message.error("请输入召回农药总数量");
        }
        if (this.recallLedger.recallPurchase == "少于进货量") {
          if (!this.lessPurchaseReason) {
            return this.$message.error("请输入少于进货量的原因");
          } else {
            this.recallLedger.recallPurchase =
              this.lessNumber + "-" + this.lessPurchaseReason;
          }
        }
        if (!this.recallLedger.disposeDate) {
          return this.$message.error("请输入处置日期");
        }
        if (!this.recallLedger.disposeNum) {
          return this.$message.error("请输入处置数量");
        }
        if (!this.recallLedger.disposeWhither) {
          return this.$message.error("请输入处置去向");
        }
        if (!this.recallLedger.sign) {
          return this.$message.error("请处置人签字");
        }
      }

      if (this.recallLedger.id) {
        editRecallLedger(this.recallLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addRecallLedger(this.recallLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    openSign() {
      this.signatureVisible = true;
    },
    signFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.recallLedger = { ...this.recallLedger, sign: res.url };
        }
      });
      this.signatureVisible = false;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  padding: 20px 50px;
  /deep/ .el-form-item {
    width: 32%;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  color: #409eff;
}
.content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  /deep/ .el-date-editor {
    .el-input__prefix,
    .el-input__suffix {
      top: -4px;
    }
  }
}
table {
  width: 100%;
}
.inline-table {
  border: 0px;
}
table,
td {
  border-collapse: collapse;
  border: 1px solid #999;
}
td {
  padding: 15px;
  font-size: 14px;
}
.sign {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}
</style>
